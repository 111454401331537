import { modifier } from 'ember-modifier';
import { assert } from '@ember/debug';

const FAST_EVENT_MILISECONDS = 100;

export default modifier(
  function barcodeScan(_, positional) {
    const callback = positional[0];

    assert(
      'First positional param to {{barcode-scan}} modifier must be a function',
      typeof callback === 'function'
    );

    let lastEventTime;
    let barcodeScanEnabled = false;
    let buffer = '';

    function resetState() {
      barcodeScanEnabled = false;
      buffer = '';
    }

    function onKeydown(event) {
      if (!lastEventTime) {
        lastEventTime = event.timeStamp;
      } else {
        let eventDiff = event.timeStamp - lastEventTime;
        lastEventTime = event.timeStamp;

        // Each keypress from the barcode scanner is usually < 100 miliseconds
        // apart. If we get two keypresses in quick succcession then we can assume
        // it's from the barcode scanner
        if (eventDiff < FAST_EVENT_MILISECONDS) {
          barcodeScanEnabled = true;
        } else {
          // this is actually the start of a new barcode so we should reset the
          // buffer to just this key
          resetState();
        }
      }

      // We only want to actually commit the barcode when we are sure that the
      // keypresses are coming from the barcode scanner. This should prevent odd
      // behaviour when the user interacts with the keyboard and for that to be
      // mistaken for a barcode scan
      if (barcodeScanEnabled && event.keyCode === 13) {
        // this prevents the enter key from interacting with the UI. This means
        // that it won't trigger an event on the currently focused element (e.g.
        // the close button on the slide-in component)
        event.preventDefault();
        event.stopPropagation();
        callback(buffer);
        resetState();
        lastEventTime = null;

        return;
      }

      buffer += event.key;
    }

    window.addEventListener('keydown', onKeydown, true);

    return () => {
      window.removeEventListener('keydown', onKeydown, true);
    };
  },
  { eager: false }
);
