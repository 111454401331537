import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { restartableTask, task, waitForProperty } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import { ACCEPTED_ISSUES } from 'my-phorest/utils/calendar';
import * as calendarCache from 'my-phorest/utils/calendar-cache';
import {
  evictBookingHistory,
  evictGroupBookings,
} from 'my-phorest/utils/graphql';
import sortAppointments from 'my-phorest/utils/sort-appointments';
import { CalendarEvent } from 'my-phorest/utils/calendar-events';
import { schedule } from '@ember/runloop';
import { AppointmentEvent } from 'my-phorest/utils/calendar-events';

import addGroupBookingAppointment from 'my-phorest/gql/mutations/add-group-booking-appointment.graphql';
import addPackageGroupBookingAppointment from 'my-phorest/gql/mutations/add-package-group-booking-appointment.graphql';
import appointmentsRefreshAfterGroupBookingQuery from 'my-phorest/gql/queries/appointments-refresh-after-group-booking.graphql';
import AppointmentFragment from 'my-phorest/gql/fragments/appointment.graphql';
import { later } from '@ember/runloop';

export default class GroupBookingService extends Service {
  @service router;
  @service appointmentSlideOver;
  @service appointmentIssues;
  @service fullCalendar;
  @service appointmentTasks;

  @queryManager apollo;

  @tracked originalAppointment = null;
  @tracked primaryClient = null;
  @tracked client = null;
  @tracked staffMember = null;
  @tracked service = null;
  @tracked package = null;
  @tracked packageOptions = null;

  close() {
    this.router.transitionTo('accounts.account.appointments');
  }

  reset() {
    this.originalAppointment = null;
    this.primaryClient = null;
    this.client = null;
    this.staffMember = null;
    this.service = null;
    this.package = null;
    this.packageOptions = null;
  }

  async createGroupBooking() {
    const primaryClientId =
      this.originalAppointment.groupBookingPrimaryClientId ??
      this.originalAppointment.client.id;
    const staffMemberId = this.staffMember.id;
    const roomId = this.originalAppointment.room?.id;
    const machineId = this.originalAppointment.machine?.id;
    const issues = ACCEPTED_ISSUES;
    const serviceId = this.service.id;
    const date = this.originalAppointment.date;
    const selectedTime = this.originalAppointment.startTime;

    const groupBooking = {
      primaryClientId,
      staffMemberId,
      machineId,
      roomId,
      serviceId,
      date,
      selectedTime,
      issues,
    };

    if (!this.client.isWalkIn) {
      groupBooking.clientId = this.client.id;
    }

    return this.addGroupBookingTask.perform(groupBooking);
  }

  async createPackageGroupBooking() {
    const primaryClientId =
      this.originalAppointment.groupBookingPrimaryClientId ??
      this.originalAppointment.client.id;
    const staffMemberId = this.staffMember.id;
    const roomId = this.originalAppointment.room?.id;
    const machineId = this.originalAppointment.machine?.id;
    const issues = ACCEPTED_ISSUES;
    const serviceGroupId = this.package.id;
    const serviceGroupItemOptionIds = this.packageOptions.map(
      (option) => option.id
    );
    const date = this.originalAppointment.date;
    const selectedTime = this.originalAppointment.startTime;

    const groupBooking = {
      primaryClientId,
      staffMemberId,
      roomId,
      machineId,
      serviceGroupId,
      serviceGroupItemOptionIds,
      date,
      selectedTime,
      issues,
    };

    if (!this.client.isWalkIn) {
      groupBooking.clientId = this.client.id;
    }

    return this.addPackageGroupBookingTask.perform(groupBooking);
  }

  @task
  *addGroupBookingTask(variables) {
    const response = yield this.apollo.mutate(
      {
        mutation: addGroupBookingAppointment,
        variables,
        update: (cache, { data: { addGroupBookingAppointment } }) => {
          const { appointment } = addGroupBookingAppointment;
          if (appointment) {
            calendarCache.addEvent(cache, {
              date: appointment.date,
              eventId: appointment.id,
              resourceId: appointment.staffMemberId,
            });
            if (appointment.machineId) {
              calendarCache.addEvent(cache, {
                date: appointment.date,
                eventId: appointment.id,
                resourceId: appointment.machineId,
                resourceType: 'Machine',
              });
            }
            if (appointment.roomId) {
              calendarCache.addEvent(cache, {
                date: appointment.date,
                eventId: appointment.id,
                resourceId: appointment.roomId,
                resourceType: 'Room',
              });
            }
            evictGroupBookings(cache, appointment.groupBookingId);
            evictBookingHistory(cache);
            cache.gc();
          }
        },
      },
      'addGroupBookingAppointment'
    );

    if (isPresent(response.issues)) {
      const issuesAccepted = yield this.appointmentIssues.askForConfirmation({
        issues: response.issues,
        context: {
          resource: this.staffMember,
          service: this.service,
        },
      });

      if (!issuesAccepted) return;

      const acceptedIssues = response.issues.map((issue) => issue.issueType);
      variables.issues = [...variables.issues, ...acceptedIssues];

      return yield this.addGroupBookingTask.perform(variables);
    }

    yield this.#updateCalendarEventForOriginalAppointment(response.appointment);
    this.fullCalendar.calendarApi.refetchResources();

    return response.appointment;
  }

  @task
  *addPackageGroupBookingTask(variables) {
    const response = yield this.apollo.mutate(
      {
        mutation: addPackageGroupBookingAppointment,
        variables,
        update: (
          cache,
          { data: { addServiceGroupGroupBookingAppointment } }
        ) => {
          const { appointments } = addServiceGroupGroupBookingAppointment;
          if (appointments) {
            appointments.forEach((appointment) => {
              calendarCache.addEvent(cache, {
                date: appointment.date,
                eventId: appointment.id,
                resourceId: appointment.staffMemberId,
              });

              if (appointment.machineId) {
                calendarCache.addEvent(cache, {
                  date: appointment.date,
                  eventId: appointment.id,
                  resourceId: appointment.machineId,
                  resourceType: 'Machine',
                });
              }

              if (appointment.roomId) {
                calendarCache.addEvent(cache, {
                  date: appointment.date,
                  eventId: appointment.id,
                  resourceId: appointment.roomId,
                  resourceType: 'Room',
                });
              }

              evictGroupBookings(cache, appointment.groupBookingId);
            });

            evictBookingHistory(cache);
            cache.gc();
          }
        },
      },
      'addServiceGroupGroupBookingAppointment'
    );
    this.fullCalendar.calendarApi.refetchResources();
    yield waitForProperty(this.fullCalendar, 'isLoading', false);
    if (isPresent(response.issues)) {
      const issuesAccepted = yield this.appointmentIssues.askForConfirmation({
        issues: response.issues,
        context: {
          resource: this.staffMember,
          service: this.service,
        },
      });

      if (!issuesAccepted) return;

      const acceptedIssues = response.issues.map((issue) => issue.issueType);
      variables.issues = [...variables.issues, ...acceptedIssues];

      return yield this.addPackageGroupBookingTask.perform(variables);
    }

    for (let i = 0, len = response.appointments.length; i < len; i++) {
      let appointment = response.appointments[i];
      yield this.#updateCalendarEventForOriginalAppointment(appointment);
      this.appointmentSlideOver.upsertAppointmentInTrackedData(
        new AppointmentEvent(appointment)
      );
    }

    return response.appointments;
  }

  @restartableTask
  *selectPackageTask(selectedPackage, options) {
    this.package = selectedPackage;
    this.packageOptions = options;

    const packageAppointments = yield this.createPackageGroupBooking();
    if (!packageAppointments) return;

    if (selectedPackage.numberOfPersons > 1 && !this.isLastInGroupBooking) {
      this.router.transitionTo(
        'accounts.account.appointments.group-bookings',
        this.originalAppointment
      );
    } else {
      if (this.originalAppointment.groupBookingId === null) {
        packageAppointments.forEach((appointment) => {
          this.appointmentSlideOver.upsertAppointmentInTrackedData(
            new AppointmentEvent(appointment)
          );
        });
      } else {
        this.appointmentSlideOver.upsertAppointmentInTrackedData(
          new AppointmentEvent(this.originalAppointment)
        );
      }

      this.router.transitionTo('accounts.account.appointments');

      this.appointmentSlideOver.isOpen = true;
      this.appointmentSlideOver.setScreen.perform('appointment-details');
      later(this, this.#scrollToBottom, 0);
    }
  }

  get isLastInGroupBooking() {
    /*
      It's possible to add another package group booking to an existing group booking
      of the same package. So for example if it's a 2 persons package and we want to
      add another one, we need to ask for 2 more persons.

      To know which person we are adding for every package we need to somehow "ignore"
      the completed packages. That's why the remainder operator (%) is used here: if we
      have 2 persons in the calendar for a 2 persons package group booking, the remainder
      will be 0, so we know that we are starting a new package group booking.
    */

    // It will be the last one if by adding the current (future) appointment
    // matches de number of persons
    return (
      this.packageGroupBookingExistingPersons % this.package.numberOfPersons ===
      0
    );
  }

  get packageGroupBookingExistingPersons() {
    let eventsInCalendar = this.groupBookingPackageAppointmentEvents.length;
    let optionsInPackage = this.packageOptions.length;

    return eventsInCalendar / optionsInPackage;
  }

  get groupBookingAppointmentEvents() {
    const { groupBookingId } = this.originalAppointment;

    return this.fullCalendar.calendarApi.getEvents().filter((event) => {
      return (
        (!!groupBookingId &&
          event.extendedProps.groupBookingId === groupBookingId) ||
        CalendarEvent.fromFCEvent(event).id === this.originalAppointment.id
      );
    });
  }

  get groupBookingPackageAppointmentEvents() {
    const groupBookingId = this.originalAppointment?.groupBookingId;
    const serviceGroupId = this.originalAppointment?.serviceGroupId;

    return this.fullCalendar.calendarApi.getEvents().filter((event) => {
      return (
        (!!groupBookingId &&
          event.extendedProps.groupBookingId === groupBookingId &&
          event.extendedProps.serviceGroupId === serviceGroupId) ||
        CalendarEvent.fromFCEvent(event).id === this.originalAppointment?.id
      );
    });
  }

  otherGroupBookingClientsInCalendar(appointment) {
    let groupBookingId = appointment.groupBookingId;
    let serviceGroupId = appointment.serviceGroupId;
    let clientId = appointment.clientId;

    return this.fullCalendar.calendarApi.getEvents().filter((event) => {
      return (
        !!groupBookingId &&
        event.extendedProps.groupBookingId === groupBookingId &&
        event.extendedProps.serviceGroupId === serviceGroupId &&
        event.extendedProps.clientId != clientId
      );
    });
  }

  async #updateCalendarEventForOriginalAppointment(newAppointment) {
    const appointmentsToRefresh = this.fullCalendar.calendarApi
      .getEvents()
      .filter((event) => {
        return (
          !event.extendedProps.groupBookingId &&
          newAppointment.groupBookingPrimaryClientId ===
            event.extendedProps.clientId &&
          newAppointment.date === event.extendedProps.date
        );
      });
    const ids = appointmentsToRefresh.map((event) => event.id.split('::')[1]);

    if (ids.length > 0) {
      await this.apollo.query({
        query: appointmentsRefreshAfterGroupBookingQuery,
        variables: {
          filterBy: {
            ids,
          },
        },
      });

      ids.forEach((apptId) => {
        let appointment = this.apollo.apollo.client.readFragment({
          id: `Appointment:${apptId}`,
          fragment: AppointmentFragment,
          fragmentName: 'appointmentFields',
        });

        this.appointmentSlideOver.upsertAppointmentInTrackedData(
          new AppointmentEvent(appointment)
        );
      });

      this.fullCalendar.calendarApi.refetchResources();
    }
  }

  #getAppointmentEventsFromAppointments(appointments) {
    return this.fullCalendar.calendarApi
      .getEvents()
      .filter((event) => {
        return appointments.some(
          (appointment) =>
            appointment.client.id === event.extendedProps.clientId &&
            appointment.date === event.extendedProps.date
        );
      })
      .map((e) => CalendarEvent.fromFCEvent(e))
      .sort(sortAppointments);
  }

  openAppointmentSlideOver(appointments) {
    this.router.transitionTo('accounts.account.appointments');
    this.appointmentSlideOver.close();

    let appointmentsList = [];

    if (Array.isArray(appointments)) {
      appointmentsList =
        this.#getAppointmentEventsFromAppointments(appointments);
    } else {
      appointmentsList = [appointments];
    }

    // We have to wait for the current render queue to finish before we can open the slide-over again.
    // This is so some of the UI elements in the slide-over can reset their state.
    schedule('afterRender', () => {
      this.appointmentSlideOver.open({
        appointments: appointmentsList,
        client: appointmentsList[0].client,
        staffMember: appointmentsList[0].staffMember,
        screen: 'appointment-details',
      });
    });
  }

  #scrollToBottom() {
    const $element = document.getElementById('details-container');

    if ($element) {
      $element.scrollTop = $element.scrollHeight;
    }
  }
}
