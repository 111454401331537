import Service from '@ember/service';
import { service } from '@ember/service';
import config from 'my-phorest/config/environment';
import currencyCode from 'my-phorest/utils/currency-code';
import { tracked } from '@glimmer/tracking';
import { dropTask, task, timeout } from 'ember-concurrency';
import timeoutForEnv from 'my-phorest/utils/timeout-for-env';
import { fromJSDate } from 'my-phorest/utils/local-date-helpers';
import { queryManager } from 'ember-apollo-client';
import fetch from 'fetch';

import createFbeConnectionMutation from 'my-phorest/gql/mutations/create-fbe-connection.graphql';
import submitAdCampaignMutation from 'my-phorest/gql/mutations/submit-ad-campaign.graphql';
import adAccountConfigQuery from 'my-phorest/gql/queries/ad-account-config.graphql';
import adCampaignRoiQuery from 'my-phorest/gql/queries/ad-campaign-roi.graphql';
import adCampaignEstimatedReachQuery from 'my-phorest/gql/queries/ad-campaign-estimated-reach.graphql';
import fbeConnectionsQuery from 'my-phorest/gql/queries/fbe-connections.graphql';
import deleteAdCampaignsMutation from 'my-phorest/gql/mutations/delete-ad-campaigns.graphql';
import duplicateAdCampaignsMutation from 'my-phorest/gql/mutations/duplicate-ad-campaign.graphql';
import updateAdCampaignMutation from 'my-phorest/gql/mutations/update-ad-campaign.graphql';
import videoStatusQuery from 'my-phorest/gql/queries/video-status.graphql';

const FBE_SCOPE = [
  'manage_business_extension',
  'ads_management',
  'ads_read',
  'pages_manage_ads',
  'pages_read_engagement',
];

const { graphApi } = config.facebook;

const POLLING_INTERVAL_MS = 1000;

export default class MetaAdsService extends Service {
  @service errorHandler;
  @service intl;
  @service metaErrorHandler;
  @service notifications;
  @service session;
  @service pendo;
  @queryManager apollo;

  @tracked isFbSdkInjected = false;
  @tracked percentageUploaded = 0;

  /**
   * Inits Facebook SDK by injecting it to our app (on demand) and configuring.
   * @returns {Promise}
   */
  initFacebookSdk() {
    return new Promise((resolve) => {
      if (this.isFbSdkInjected) {
        return resolve();
      }
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: config.facebook.appId, // Phorest FB App ID
          status: true,
          cookie: true, // enable cookies to allow the server to access the session
          xfbml: true, // parse social plugins on this page
          version: 'v20.0', // uses graph api version v20.0
        });
        this.isFbSdkInjected = true;
        resolve();
      };

      this._injectFacebookSdk();
    });
  }

  /**
   * Opens Facebook SDK's login flow.
   * @returns {Promise}
   */
  login(loginCredentials) {
    return new Promise((resolve, reject) => {
      const config = this._getBusinessSetUp(loginCredentials);

      FB.login((response) => {
        if (response.status === 'connected') {
          resolve(response);
        } else {
          reject(response);
        }
      }, config);
    });
  }

  /**
   * Opens Facebook SDK's payment flow.
   * @returns {Promise}
   */
  managePaymentMethod(account_id) {
    return new Promise((resolve) => {
      FB.ui(
        {
          account_id,
          display: 'popup',
          method: 'ads_payment',
        },
        (response) => {
          resolve(response);
        }
      );
    });
  }

  async uploadImageToFacebook(image, fbeConnection) {
    const url = new URL(
      `${graphApi}/${fbeConnection.apiAdAccountId}/adimages?access_token=${fbeConnection.accessToken}`
    );
    const formData = new FormData();
    formData.append('file', image);
    return await this.#sendMediaRequest(url, formData);
  }

  @dropTask
  *uploadVideoToFacebook(video, fbeConnection) {
    const url = new URL(
      `${graphApi}/${fbeConnection.apiAdAccountId}/advideos?access_token=${fbeConnection.accessToken}`
    );
    const {
      upload_session_id: uploadSessionId,
      start_offset: firstStartOffset,
      end_offset: firstEndOffset,
      video_id: videoId,
    } = yield this.startVideoUploadSession.perform(url, video.size);
    yield this.transferChunkedFile.perform(
      url,
      uploadSessionId,
      video,
      firstStartOffset,
      firstEndOffset
    );
    yield this.finishVideoUploadSession.perform(url, uploadSessionId);
    return videoId;
  }

  async submitAdCampaign(variables) {
    return await this.#mutate(
      {
        mutation: submitAdCampaignMutation,
        variables,
        update: (cache) => {
          this.#evictAndCollectCache(cache, 'adCampaigns');
        },
      },
      'submitAdCampaign'
    );
  }

  triggerPendoEvent(adCampaign) {
    let restructuredCampaign = {
      name: adCampaign.name,
      adPlacements: adCampaign.adPlacements,
      audienceType: adCampaign.audienceType,
      dailyBudget: adCampaign.adBudget.amountPerDay,
      duration: adCampaign.adBudget.days,
      primaryText: adCampaign.adCreative.primaryText,
      headline: adCampaign.adCreative.headline,
      isUsingImage: adCampaign.adCreative.imageHash ? true : false,
      isUsingVideo: adCampaign.adCreative.videoId ? true : false,
      attributionWindow: Math.max(
        adCampaign.attributionWindow?.click,
        adCampaign.attributionWindow?.view
      ),
      createdAt: adCampaign.createdAt,
      updatedAt: adCampaign.updatedAt,
      startDate: adCampaign.startDate,
      endDate: adCampaign.endDate,
      customUrl: adCampaign.customUrl,
      isEditedAfterSubmission: adCampaign.status === 'SUBMITTED' ? true : false,
    };

    this.pendo.trackEvent('Ads Manager', restructuredCampaign);
  }

  @dropTask
  *createFbeConnection() {
    const { branch } = this.session;
    const now = fromJSDate(new Date());

    return yield this.#mutate(
      {
        mutation: createFbeConnectionMutation,
        variables: {
          input: {
            label: `${branch.shortName} - ${now}`,
          },
        },
        update: (cache, { data: { createFbeConnection } }) => {
          const { fbeConnections } = cache.readQuery({
            query: fbeConnectionsQuery,
          });
          cache.writeQuery({
            query: fbeConnectionsQuery,
            data: {
              fbeConnections: [...fbeConnections, createFbeConnection],
            },
          });
        },
      },
      'createFbeConnection'
    );
  }

  @dropTask
  *getFbeConnections(fbeConnectionId) {
    return yield this.apollo.watchQuery(
      {
        query: fbeConnectionsQuery,
        variables: {
          fbeConnectionId,
        },
        fetchPolicy: 'cache-and-network',
      },
      'fbeConnections'
    );
  }

  @task
  *getAdAccountConfig(id) {
    return yield this.#query(
      {
        query: adAccountConfigQuery,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      },
      'adAccountConfig'
    );
  }

  @task
  *getAdCampaignRoi(variables) {
    return yield this.#query(
      {
        query: adCampaignRoiQuery,
        variables,
      },
      'adCampaignRoi'
    );
  }

  @task
  *getAdCampaignEstimatedReach(variables) {
    return yield this.#query(
      {
        query: adCampaignEstimatedReachQuery,
        fetchPolicy: 'network-only',
        variables,
      },
      'adCampaignEstimatedReach'
    );
  }

  @dropTask
  *deleteAdCampaigns(ids) {
    return yield this.#mutate(
      {
        mutation: deleteAdCampaignsMutation,
        variables: {
          ids,
        },
        update: (cache) => {
          this.#evictAndCollectCache(cache, 'adCampaigns');
        },
      },
      'deleteAdCampaigns'
    );
  }

  @dropTask
  *duplicateAdCampaign(id) {
    return yield this.#mutate(
      {
        mutation: duplicateAdCampaignsMutation,
        variables: {
          id,
        },
        update: (cache) => {
          this.#evictAndCollectCache(cache, 'adCampaigns');
        },
      },
      'duplicateAdCampaign'
    );
  }

  @dropTask
  *updateAdCampaign(id, input) {
    return yield this.#mutate(
      {
        mutation: updateAdCampaignMutation,
        variables: {
          id,
          input,
        },
        update: (cache) => {
          this.#evictAndCollectCache(cache, 'adCampaign');
        },
      },
      'updateAdCampaign'
    );
  }

  @dropTask
  *checkVideoPreviewGenerated(adCampaignId, videoId) {
    let videoStatus = 'processing';
    while (videoStatus === 'processing') {
      yield timeout(timeoutForEnv(POLLING_INTERVAL_MS));
      const response = yield this.#query(
        {
          query: videoStatusQuery,
          variables: {
            adCampaignId,
            videoId,
          },
          fetchPolicy: 'network-only',
        },
        'videoStatus'
      );
      videoStatus = response.videoStatus;
    }
  }

  _getBusinessSetUp(loginCredentials) {
    let setup = {
      scope: FBE_SCOPE,
      extras: this._getBusinessSetUpExtras(loginCredentials),
      return_scopes: true,
    };

    return setup;
  }

  _getBusinessSetUpExtras(loginCredentials) {
    let { branch } = this.session;
    let { countryCode } = branch.locale;

    let currency = currencyCode(countryCode);
    let name = branch.shortName;
    let timezone = branch.timezone;

    return {
      setup: {
        external_business_id: loginCredentials.fbeConnection.id,
        timezone,
        currency,
        business_vertical: 'APPOINTMENTS',
        ...this._saveBusinessCredentialsInSetup(loginCredentials),
      },
      business_config: {
        business: {
          name,
        },
        page_cta: this._getFbPageCtaSetup(loginCredentials.hasValidPageCta),
        ig_cta: this._getIgCtaSetup(loginCredentials.hasValidInstagramCta),
      },
      repeat: false,
    };
  }

  _getCtaButtonUrl() {
    let { business, branch } = this.session;
    const onlineBookingURL = `${config.APP.onlineBookingRootURL}/${branch.domainName}`;
    const onlineBookingBranchPickerURL =
      config.APP.onlineBookingBranchPickerURL.replace(
        '$DOMAIN_NAME',
        business.domainName
      );

    return business.isSingleBranch
      ? onlineBookingURL
      : onlineBookingBranchPickerURL;
  }

  _getFbPageCtaSetup(hasValidPageCta) {
    const ctaUrl = this._getCtaButtonUrl();
    return {
      enabled: hasValidPageCta ? true : false,
      cta_button_text: 'Book Now',
      cta_button_url: hasValidPageCta ? ctaUrl : 'https://phorest.com',
    };
  }

  _getIgCtaSetup(hasValidInstagramCta) {
    const ctaUrl = this._getCtaButtonUrl();
    return {
      enabled: hasValidInstagramCta ? true : false,
      cta_button_text: 'Book Now',
      cta_button_url: hasValidInstagramCta ? ctaUrl : 'https://phorest.com',
    };
  }

  /**
   * Injects loading script for FacebookSDK. We inject it asynchronously, on demand.
   * (not by injecting to every page as a typical way of doing these things)
   *
   * @private
   */
  _injectFacebookSdk() {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.async = true;
      js.defer = true;
      js.crossOrigin = 'anonymous';
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  _saveBusinessCredentialsInSetup(loginCredentials) {
    const {
      fbeConnection: {
        businessManagerId,
        adAccountId,
        pages,
        instagramProfiles,
        pixelId,
      },
      reAuthenticateAdsPermissions,
    } = loginCredentials;

    const setup = {};

    if (reAuthenticateAdsPermissions) {
      if (businessManagerId) setup['business_manager_id'] = businessManagerId;
      if (adAccountId) setup['ad_account_id'] = adAccountId;
      if (pages?.firstObject) setup['page_id'] = pages.firstObject;
      if (instagramProfiles?.firstObject)
        setup['ig_profile_id'] = instagramProfiles.firstObject;
      if (pixelId) setup['pixel_id'] = pixelId;
    }

    return setup;
  }

  async #mutate(params, mutation) {
    try {
      return await this.apollo.mutate(params, mutation);
    } catch (error) {
      const metaError = this.metaErrorHandler.handle(error);
      throw metaError;
    }
  }

  async #query(params, query) {
    try {
      return await this.apollo.query(params, query);
    } catch (error) {
      const metaError = this.metaErrorHandler.handle(error);
      throw metaError;
    }
  }

  #evictAndCollectCache(cache, fieldName) {
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: fieldName,
    });
    cache.gc();
  }
  @dropTask
  *startVideoUploadSession(url, fileSize) {
    const formData = new FormData();
    formData.append('upload_phase', 'start');
    formData.append('file_size', Number(fileSize));
    this.percentageUploaded = 0;

    return yield this.#sendMediaRequest(url, formData);
  }

  @dropTask
  *transferChunkedFile(
    url,
    uploadSessionId,
    file,
    firstStartOffset,
    firstEndOffset
  ) {
    const formData = new FormData();
    formData.append('upload_phase', 'transfer');
    formData.append('upload_session_id', uploadSessionId);
    let startOffset = Number(firstStartOffset);
    let endOffset = Number(firstEndOffset);

    while (startOffset < endOffset) {
      const blob = file.slice(startOffset, endOffset + 1);

      formData.append('start_offset', startOffset);
      formData.append('video_file_chunk', blob);
      const { start_offset, end_offset } = yield this.#sendMediaRequest(
        url,
        formData
      );
      startOffset = Number(start_offset);
      endOffset = Number(end_offset);
      formData.delete('start_offset');
      formData.delete('video_file_chunk');

      this.percentageUploaded = Math.round((endOffset * 100) / file.size);
    }
  }

  @dropTask
  *finishVideoUploadSession(url, uploadSessionId) {
    const formData = new FormData();
    formData.append('upload_phase', 'finish');
    formData.append('upload_session_id', uploadSessionId);

    return yield this.#sendMediaRequest(url, formData);
  }

  async #sendMediaRequest(url, formData) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      return await response.json();
    } catch (e) {
      this.errorHandler.handle(e, { showError: false });
      return this.notifications.failure(
        this.intl.t('global.error-notification-title'),
        this.intl.t(
          'marketing.meta-ads.campaigns.error.error-notification-text'
        )
      );
    }
  }
}
